<template>
  <div>
    <NavbarMain />
    <img src="@/assets/conocerutas1400.jpg" class="img-fluid" alt="..." />
    <div class="container" style="zoom: 80%; ">
      <div class="titulo">
        <p>Novedades</p>
      </div>

      <div v-show="showProgressNovedades">
        <ProgressBar mode="indeterminate" style="height: .5em" />
      </div>

      <div class="row row-cols-1 row-cols-md-3">
        <div
          v-for="publicacion in publicaciones"
          :key="publicacion.id"
          class="mb-4"
        >
          <Noticias
            :imagen="publicacion.imagen"
            :titulo="publicacion.titulo"
            :fecha="publicacion.fecha"
            :id_publicacion="publicacion.id"
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import NavbarMain from "@/components/NavbarMain.vue";
import Noticias from "@/components/Noticias.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      publicaciones: [],
      showProgressNovedades: false,
    };
  },

  components: {
    NavbarMain,
    Noticias,
    Footer,
  },

  created: function() {
    this.getnovedades();
  },

  computed: {
    imagenpublicacion() {
      return {
        ...this.publicacion,
        icon:
          this.publicacion.imagen &&
          require(`@/assets/${this.publicacion.imagen}`),
      };
    },
  },

  methods: {
    getnovedades: async function() {
      let vue = this;
      this.showProgressNovedades = true;
      await this.axios
        .get("publicaciones.php?dato=getpublicacionesapi")
        .then(function(response) {
          vue.publicaciones = response.data;
        })
        .catch((error) => {
          console.log("ERROR: " + error);
          console.log("ERROR responseText: " + error.responseText);
          console.log("ERROR STATUS: " + error.status);
          console.log("ERROR TEXT_STATUS: " + error.textStatus);
        })
        .then(function() {
          vue.showProgressNovedades = false;
        });
    }


  },

};
</script>

<style>

.titulo p {
  color: #004d27;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}

.grid_noticias {
  margin-bottom: 20px;
}

.nt-inicio .card img {
    height: 200px;
    object-fit: cover;
}

.nt-inicio .card{
    height: 100%
}

</style>
