import { createRouter, createWebHistory } from 'vue-router'
import Inicio from "../views/Inicio.vue";
import Home from "../views/Home.vue";

import AtencionAlCliente from "../components/AtencionAlCliente.vue";
import Noticia from "../views/Noticia.vue";
import Subir from "../views/Subir.vue";
import Rutas from "../views/Rutas.vue";
import Politicas from "../views/Politicas.vue";
import PuntosVenta from "../views/PuntosVenta.vue";
import Certificado from "../views/Certificado.vue";
import CertificadoSE from "../views/CertificadoSE.vue";
import IdaVuelta from "../views/IdaVuelta.vue";
import PageNotFound from "../views/PageNotFound.vue";
import FormPQR from "../components/FormPQR.vue";
import Prueba from "../views/Prueba.vue";
import SliderCustom from "../components/SliderCustom.vue";

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },

  {
    path: "/inicio",
    name: "Inicio",
    component: Inicio,
  },
  {
    path: "/atencion-al-cliente",
    name: "AtencionAlCliente",
    component: AtencionAlCliente,
  },
  {
    path: "/noticias/noticia/:id",
    name: "Noticia",
    component: Noticia,
  },
  {
    path: "/subir",
    name: "Subir",
    component: Subir,
  },
  {
    path: "/pqr",
    name: "FormPQR",
    component: FormPQR,
  },
  {
    path: "/rutas",
    name: "Rutas",
    component: Rutas,
  },
  {
    path: "/politicas",
    name: "Politicas",
    component: Politicas,
  },
  {
    path: "/puntosdeventa",
    name: "PuntosVenta",
    component: PuntosVenta,
  },
  {
    path: "/certificado-de-viaje",
    name: "Certificado",
    component: Certificado,
  },
  {
    path: "/",
    name: "CertificadoSE",
    component: CertificadoSE,
  },
  {
    path: "/ida-y-vuelta",
    name: "IdaVuelta",
    component: IdaVuelta,
  },
  {
    path: "/prueba",
    name: "Prueba",
    component: Prueba,
  },
  {
    path: "/slider",
    name: "SliderCustom",
    component: SliderCustom,
  },

  {
    path: "/:catchAll(.*)",
    component: PageNotFound,
    name: "PageNotFound",
  },

  {
    path: "/test",
    name: "Home",
    component: Home,
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
