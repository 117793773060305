<template>
  <div>
    <NavbarMain />
    <img src="@/assets/contactanos1400.jpg" class="img-fluid" alt="..." />
    <div class="container" style="zoom: 80%; ">
      <div class="form-row">
        <div class="form-group col-md-12" style="text-align: center">
          <div class="titulo">
            <p>Atención al cliente</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <img src="@/assets/PQR_RADICAR.png" class="img-fluid" />
        </div>
        <div class="form-group col-md-6">
          <img src="@/assets/PQR_BUSCAR.png" class="img-fluid" />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <!-- Button open modal Radicar -->
          <button
            type="button"
            class="btn btn-light btn-berlinave btn-lg"
            data-toggle="modal"
            data-target="#modal-radicar"
          >
            <span class="text">Radicar Solicitud</span>
          </button>
        </div>
        <div class="form-group col-md-6">
          <!-- Button open modal consultar -->
          <button
            type="button"
            class="btn btn-light btn-berlinave btn-lg"
            data-toggle="modal"
            data-target="#modal-consultar"
          >
            Consultar Radicado
          </button>
        </div>
      </div>
    </div>

    <!-- Modal Radicar-->
    <div
      class="modal fade"
      id="modal-radicar"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Radicar solicitud
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="mensaje-habbeas-data">
                  Conforme al Decreto 1377 de 2013, reglamentario de la Ley 1581
                  de 2012 en materia de protección de datos personales, COMPAÑÍA
                  LIBERTADOR S.A. solicita a todas las personas naturales
                  registradas en nuestras bases de datos el cumplimiento de las
                  obligaciones legales que permitan y autoricen la continuación
                  del tratamiento de sus datos personales, en los términos
                  previstos en la política de tratamiento de datos personales de
                  COMPAÑÍA LIBERTADOR S.A. utilizadas con las finalidades de dar
                  manejo de PQR´S
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label v-if="spncombospqr" for="spinnercombospqr"
                    >Espere mientras cargan todos los datos...</label
                  >
                  <div
                    v-if="spncombospqr"
                    id="spinnercombospqr"
                    class="spinner-border text-success"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label v-if="spnmunicipios" for="spinnermunicipios"
                    >Espere mientras cargan todos los municipios...</label
                  >
                  <div
                    v-if="spnmunicipios"
                    id="spinnermunicipios"
                    class="spinner-border text-success"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-3">
                  <select
                    v-model="pqr.tipo_documento"
                    id="pqr_tipodocumento"
                    class="form-control"
                  >
                    <option class="itemselect" disabled value="0"
                      >Seleccione tipo documento...</option
                    >
                    <option class="itemselect"
                      v-for="tipodocumento in tiposDeDocumentos"
                      :key="tipodocumento.id"
                      :value="tipodocumento.id"
                    >
                      {{ tipodocumento.detalle }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-3">
                  <input
                    class="form-control"
                    type="number"
                    v-model="pqr.documento"
                    id="pqr_documento"
                    placeholder="N° de documento"
                  />
                </div>
                <div class="form-group col-md-6">
                  <input
                    class="form-control"
                    type="text"
                    v-model="pqr.nombrecompleto"
                    id="pqr_nombrecompleto"
                    placeholder="Nombre completo"
                    maxlength="75"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <select
                    v-model="pqr.departamento"
                    @change="consultarMunicipios()"
                    id="pqr_departamento"
                    class="form-control"
                  >
                    <option disabled value="0"
                      >Seleccione el departamento...</option
                    >
                    <option
                      v-for="departamento in departamentos"
                      :key="departamento.id"
                      :value="departamento.id"
                    >
                      {{ departamento.detalle }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <select
                    v-model="pqr.municipio"
                    id="pqr_municipio"
                    class="form-control"
                  >
                    <option disabled value="0"
                      >Seleccione el municipio...</option
                    >
                    <option
                      v-for="municipio in municipios"
                      :key="municipio.id"
                      :value="municipio.id"
                    >
                      {{ municipio.detalle }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <select
                    v-model="pqr.tipopqr"
                    id="pqr_tipopqr"
                    class="form-control"
                  >
                    <option disabled value="0"
                      >Seleccione el tipo de PQR...</option
                    >
                    <option
                      v-for="tipopqr in tiposPqr"
                      :key="tipopqr.id"
                      :value="tipopqr.id"
                    >
                      {{ tipopqr.detalle }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <select
                    v-model="pqr.lugarservicio"
                    id="pqr_lugarservicio"
                    class="form-control"
                  >
                    <option disabled value="0"
                      >Seleccione el Lugar o Servicio...</option
                    >
                    <option
                      v-for="lugarservicio in lugaresYServicios"
                      :key="lugarservicio.id"
                      :value="lugarservicio.id"
                    >
                      {{ lugarservicio.detalle }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <input
                    class="form-control"
                    type="text"
                    v-model="pqr.telefono"
                    id="pqr_telefono"
                    placeholder="Telefono"
                  />
                </div>
                <div class="form-group col-md-3">
                  <input
                    class="form-control"
                    type="text"
                    v-model="pqr.telefono2"
                    id="pqr_telefono2"
                    placeholder="Telefono 2"
                  />
                </div>
                <div class="form-group col-md-3">
                  <input
                    class="form-control"
                    type="text"
                    v-model="pqr.correo"
                    id="pqr_correo"
                    placeholder="Correo"
                  />
                </div>
                <div class="form-group col-md-3">
                  <input
                    class="form-control"
                    type="text"
                    v-model="pqr.direccion"
                    id="pqr_direccion"
                    placeholder="Dirección"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <textarea
                    class="form-control"
                    v-model="pqr.pqr"
                    id="pqr_pqr"
                    rows="3"
                    placeholder="Escriba su PQR"
                  ></textarea>
                </div>
              </div>

              <div class="form-group">
                <input
                  type="file"
                  class="form-control-file"
                  id="exampleFormControlFile1"
                  ref="pqr_files"
                  @change="onFileChange"
                  multiple
                />
              </div>
              <div v-show="showProgress">
                <ProgressBar mode="indeterminate" style="height: .5em" />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              type="button"
              @click="enviarPQR"
              class="btn btn-primary btn-berlinave"
            >
              Radicar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal consultar-->
    <div
      class="modal fade"
      id="modal-consultar"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Consultar solicitud
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <input
                    class="form-control"
                    type="text"
                    v-model="searchpqr.documento"
                    id="view_pqr_documento"
                    placeholder="Consultar por documento"
                    @keyup.enter="buscarPqrXDocumento"
                    maxlength="10"
                  />
                </div>

                <div class="form-group col-md-6">
                  <input
                    class="form-control"
                    type="text"
                    v-model="searchpqr.radicado"
                    id="view_pqr_radicado"
                    placeholder="Consultar por Radicado"
                    @keyup.enter="buscarPqrXRadicado"
                    maxlength="30"
                  />
                </div>
              </div>

              <div  v-show="showProgressView">
                <ProgressBar mode="indeterminate" style="height: .5em" />
              </div>

              <table class="table table-striped">
                <thead style="background-color: #004D27; color: white">
                  <tr>
                    <th scope="col">RADICADO</th>
                    <th scope="col">LUGAR</th>
                    <th scope="col">TIPO PQR</th>
                    <th scope="col">FECHA RAD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="p in pqrs" :key="p.pqr_radicado">
                    <td>{{ p.pqr_radicado }}</td>
                    <td>{{ p.lgpqr_detalle }}</td>
                    <td>{{ p.tppqr_detalle }}</td>
                    <td>{{ p.pqr_fecharadicacion }}</td>

                  </tr>
                </tbody>
              </table>
            </form>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
//import InputText from "primevue/inputtext";
/*
import Dropdown from "primevue/dropdown";
import AutoComplete from "primevue/autocomplete";
import Calendar from "primevue/calendar";
import CascadeSelect from "primevue/cascadeselect";
*/
const $ = require("jquery");
window.$ = $;
import NavbarMain from "@/components/NavbarMain.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      pqrs: [],
      spncombospqr: false,
      spnmunicipios: false,
      limpiarcampos: false,
      showProgress: false,
      showProgressView: false,
      tiposDeDocumentos: [],
      departamentos: [],
      municipios: [],
      tiposPqr: [],
      lugaresYServicios: [],
      selectedFiles: [],
      sizeSelectedFiles: 0,

      //TIPO DOC OBJ
      tipodocumento: {
        id: 0,
        detalle: "",
      },

      //PQR OBJ
      pqr: {
        tipo_documento: 0,
        documento: "",
        nombrecompleto: "",
        departamento: 0,
        municipio: 0,
        tipopqr: 0,
        lugarservicio: 0,
        telefono: "",
        telefono2: "",
        correo: "",
        direccion: "",
        pqr: "",
      },

      searchpqr: {
        documento: "",
        radicado: "",
      },

      viewpqr: {
        documento: "",
        radicado: "",
      },
    };
  },

  created: function() {
    //this.$utilman.fn1();
    this.getTiposDeDocumentos();
    this.getDepartamentos();
    this.getTiposPQR();
    this.getLugaresServicios();
  },

  computed: {
    validarEnviarPQR() {
      var mensajeAlerta = "";
      if (this.pqr.tipo_documento === 0) {
        mensajeAlerta = "Debe seleccionar un tipo de documento de identidad!";
      } else {
        if (
          this.pqr.documento === "" ||
          !this.$utilidad.validarCedula(this.pqr.documento)
        ) {
          mensajeAlerta = "Debe escribir un documento de identidad válido!";
        } else {
          if (
            this.pqr.nombrecompleto === "" ||
            this.pqr.nombrecompleto.length < 10
          ) {
            mensajeAlerta = "Debe escribir un nombre válido!";
          } else {
            if (this.pqr.municipio === 0) {
              mensajeAlerta = "Debe seleccionar un municipio!";
            } else {
              if (this.pqr.tipopqr === 0) {
                mensajeAlerta = "Debe seleccionar un tipo de PQR!";
              } else {
                if (this.pqr.lugarservicio === 0) {
                  mensajeAlerta = "Debe seleccionar un lugar o servicio!";
                } else {
                  if (
                    this.pqr.telefono === "" ||
                    !this.$utilidad.validarTelefono(this.pqr.telefono)
                  ) {
                    mensajeAlerta = "Debe digitar un N° de teléfono válido!";
                  } else {
                    if (
                      this.pqr.correo === "" ||
                      !this.$utilidad.validarCorreo(this.pqr.correo) ||
                      this.pqr.correo.length > 30
                    ) {
                      mensajeAlerta =
                        "Debe digitar un correo electrónico válido!";
                    } else {
                      if (
                        this.pqr.pqr === "" ||
                        this.pqr.pqr.length < 50 ||
                        this.pqr.pqr.length > 1200
                      ) {
                        mensajeAlerta =
                          "Debe escribir su PQR, la PQR debe contener mínimo 50 caracteres y máximo 1200 caracteres!";
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (this.pqr.telefono2 !== "") {
        if (!this.$utilidad.validarTelefono(this.pqr.telefono2)) {
          mensajeAlerta = "Debe digitar un N° de teléfono válido!";
        }
      } else {
        if (this.pqr.direccion !== "") {
          if (this.pqr.direccion.length > 50) {
            mensajeAlerta = "La dirección debe tener máximo 50 caracteres!";
          }
        }
      }
      return mensajeAlerta;
    },
  },

  methods: {
    getTiposDeDocumentos: async function() {
      let vue = this;
      this.spncombospqr = true;
      await this.axios
        .get("pqr.php?dato=gettiposdedocumentos")
        .then(function(response) {
          vue.tiposDeDocumentos = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function() {
          vue.spncombospqr = false;
        });
    },

    getDepartamentos: async function() {
      this.spncombospqr = true;
      let vue = this;
      await this.axios
        .get("pqr.php?dato=getdepartamentos")
        .then(function(response) {
          vue.departamentos = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function() {
          vue.spncombospqr = false;
        });
    },

    consultarMunicipios: async function() {
      this.spnmunicipios = true;
      let vue = this;
      await this.axios
        .get("pqr.php?dato=getmunicipios&pqr_dpto=" + this.pqr.departamento)
        .then(function(response) {
          vue.municipios = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function() {
          vue.spnmunicipios = false;
        });
    },

    getTiposPQR: async function() {
      this.spncombospqr = true;
      let vue = this;
      await this.axios
        .get("pqr.php?dato=gettiposdepqr")
        .then(function(response) {
          vue.tiposPqr = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function() {
          vue.spncombospqr = false;
        });
    },

    getLugaresServicios: async function() {
      this.spncombospqr = true;
      let vue = this;
      await this.axios
        .get("pqr.php?dato=getlugaresdepqr")
        .then(function(response) {
          vue.lugaresYServicios = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function() {
          vue.spncombospqr = false;
        });
    },

    enviarPQR: async function() {
      try {
        var mensaje = this.validarEnviarPQR;

        if (mensaje === "") {
          this.showProgress = true;
          let formData = new FormData();
          formData.append("file", this.selectedFile);

          for (var i = 0; i < this.sizeSelectedFiles; i++) {
            formData.append("file[]", this.selectedFiles[i]);
          }
          //JSON.stringify(Object.fromEntries(formData))
          formData.append("pqr_tipodocumento", this.pqr.tipo_documento);
          formData.append("pqr_documento", this.pqr.documento);
          formData.append("pqr_nombre", this.pqr.nombrecompleto);
          formData.append("pqr_municipio", this.pqr.municipio);
          formData.append("pqr_tipopqr", this.pqr.tipopqr);
          formData.append("pqr_lugarservicio", this.pqr.lugarservicio);
          formData.append("pqr_telefono", this.pqr.telefono);
          formData.append("pqr_telefono2", this.pqr.telefono2);
          formData.append("pqr_correo", this.pqr.correo);
          formData.append("pqr_direccion", this.pqr.direccion);
          formData.append("pqr_pqr", this.pqr.pqr);

          let self = this;
          await self
            .axios({
              method: "post",
              url: "pqr.php?dato=insertarpqrweb",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (self.$utilidad.confirmInsert(response)) {
                var mensajeEmail = self.$utilidad.confirmSendEmail(response);
                self.$swal.fire(
                  "Transacción Exitosa!",
                  "SE HA GUARDADO CORRECTAMENTE " + mensajeEmail,
                  "success"
                );
                self.cleanFieldsNewPQR();
                $("#modal-radicar").modal("hide");
              } else if (response.data.includes("HUBO UN ERROR DE EXCEPCIÓN")) {
                self.$swal.fire("Error!", response.data, "error");
              } else {
                self.$swal.fire("Error!", "Error desconocido", "error");
                console.log(response.data);
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .finally(() => {
              self.showProgress = false;
            });
        } else {
          this.$swal.fire("Advertencia!", mensaje, "warning");
        }
      } catch (e) {
        console.log("Exception Javascript: " + e);
      }
    },

    buscarPqrXDocumento: async function() {
      var datosEnviar = {
        pqr_cedula_consulta: this.searchpqr.documento,
      };
      try {
        this.showProgressView = true;
        let self = this;
        await self.axios
          .post(
            "pqr.php?dato=gettablapqrsxcedulaapi",
            JSON.stringify(datosEnviar)
          )
          .then(function(response) {
            console.log(response.data);
            self.pqrs = response.data;
            //vue.tiposDeDocumentos = response.data;
          })
          .catch((error) => {
            console.log("ERROR: " + error);
            console.log("ERROR responseText: " + error.responseText);
            console.log("ERROR STATUS: " + error.status);
            console.log("ERROR TEXT_STATUS: " + error.textStatus);
          })
          .finally(() => {
            console.log("ALLWAYS...");
            this.showProgressView = false;
          });
      } catch (e) {
        console.log("Exception Javascript: " + e);
      }
    },

    buscarPqrXRadicado: async function() {
      var datosEnviar = {
        pqr_radicado_consulta: this.searchpqr.radicado,
      };
      try {
        this.showProgressView = true;
        let self = this;
        await self.axios
          .post(
            "pqr.php?dato=gettablapqrsxradicadoapi",
            JSON.stringify(datosEnviar)
          )
          .then(function(response) {
            console.log(response.data);
            self.pqrs = response.data;
            //vue.tiposDeDocumentos = response.data;
          })
          .catch((error) => {
            console.log("ERROR: " + error);
            console.log("ERROR responseText: " + error.responseText);
            console.log("ERROR STATUS: " + error.status);
            console.log("ERROR TEXT_STATUS: " + error.textStatus);
          })
          .finally(() => {
            console.log("ALLWAYS...");
            this.showProgressView = false;
          });
      } catch (e) {
        console.log("Exception Javascript: " + e);
      }
    },

    consultarpqr: function(radicado){
      alert(radicado);
    },

    onFileChange(event) {
      //console.log(event);
      this.sizeSelectedFiles = event.target.files.length;
      this.selectedFiles = event.target.files;
    },

    cleanFieldsNewPQR() {
      this.pqr = {
        tipo_documento: 0,
        documento: "",
        nombrecompleto: "",
        departamento: 0,
        municipio: 0,
        tipopqr: 0,
        lugarservicio: 0,
        telefono: "",
        telefono2: "",
        correo: "",
        direccion: "",
        pqr: "",
      };

      this.sizeSelectedFiles = 0;
      this.selectedFiles = [];
      this.$refs.pqr_files.value = null;
    },

    mensajeDeAlerta(mensaje) {
      this.$swal.fire("Advertencia!", mensaje, "warning");
    },
  },

  components: {
    NavbarMain,
    Footer,
  },
};
</script>

<style>
.mensaje-habbeas-data {
  margin-bottom: 20px;
  font-size: 13px;
}



.titulo p {
  color: #004d27;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
  margin-top: 10px;
}

.btn-berlinave {
  background-color: #004d27 !important;
  color: white !important;
}
</style>
