<template>
  <div class="inicio">
    <NavbarMain />
    <Slider />

    <!--
    <div class="container">
      <div class="titulo">
        <p>Novedades</p>
      </div>
-->

    <div style="zoom: 80%" class="container mt-4 nt-inicio">
      <div class="form-row">
        <div class="form-group col-md-12" style="text-align: center">
          <div class="titulorutas">
            <p>
              ¿A dónde quieres viajar? Conoce nuestras rutas y oferta del
              servicio:
            </p>
          </div>
        </div>
      </div>

      <div v-show="showProgressRutas">
        <ProgressBar mode="indeterminate" style="height: 0.5em" />
      </div>

      <div class="grid formorigendestino">

        <div class="col">
          <div class="col-12 md:col-4">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                Rutas disponibles desde:
              </span>
              <Dropdown
            v-model="rutaorigen"
            :options="origenes"
            @change="consultarDestinos()"
            optionLabel="detalle"
            placeholder="Seleccionar ciudad de Origen"
          />
            </div>
          </div>
        </div>


        <div class="col">
          <div class="col-12 md:col-4">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                Destino
              </span>
              <Dropdown
                v-model="rutadestino"
                :options="origenes"
                @change="consultarOrigenesyDestinos()"
                optionLabel="detalle"
                placeholder="Seleccionar ciudad de Destino"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <table class="table table-striped">
          <thead style="background-color: #004d27; color: white">
            <tr>
              <th scope="col">ORIGEN</th>
              <th scope="col">DESTINO</th>
              <th scope="col">TARIFA</th>
              <!-- <th scope="col">TARIFA IDA/REGRESO</th> -->
              <th scope="col">HORA/FRECUENCIA</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ruta in rutas" :key="ruta.id">
              <td>{{ ruta.origen }}</td>
              <td>{{ ruta.destino }}</td>
              <td>{{ ruta.tarifa }}</td>
              <!-- <td>{{ ruta.tarifapromo }}</td> -->
              <td>{{ ruta.hora }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import NavbarMain from "@/components/NavbarMain.vue";
import Slider from "@/components/Slider.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Inicio",

  data() {
    return {
      showProgressRutas: false,
      origenes: [],
      rutas: [],
      destinos: [],
      rutaorigen: null,
      rutadestino: null,
    };
  },

  components: {
    NavbarMain,
    Slider,
    Footer,
  },

  created: function () {
    this.getOrigenes();
  },

  computed: {
    //<!-- :src="require(`@/assets/${publicacion.imagen}`)" -->
  },

  methods: {
    getOrigenes: async function () {
      this.showProgressRutas = true;
      let vue = this;
      await this.axios
        .get("rutasdisponibles.php?dato=getciudades")
        .then(function (response) {
          vue.origenes = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.showProgressRutas = false;
        });
    },

    consultarDestinos: async function () {
      this.showProgressRutas = true;
      let vue = this;
      await this.axios
        .get(
          "rutasdisponibles.php?dato=getrutasxorigen&ruta_origen=" +
            this.rutaorigen.id
        )
        .then(function (response) {
          vue.rutas = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.showProgressRutas = false;
        });
    },

    consultarOrigenesyDestinos: async function () {
      this.showProgressRutas = true;
      let vue = this;
      await this.axios
        .get(
          "rutasdisponibles.php?dato=getrutasxorigenydestino&ruta_origen=" +
            this.rutaorigen.id +
            "&ruta_destino=" +
            this.rutadestino.id
        )
        .then(function (response) {
          vue.rutas = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.showProgressRutas = false;
        });
    },
  },
};
</script>

<style>
.titulorutas p {
  color: #004d27;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}

.formorigendestino {
  margin-top: 10px;
}

.btn-berlinave {
  background-color: #004d27 !important;
  color: white !important;
}
</style>
