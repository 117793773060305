import { createApp } from 'vue'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faUser, fas } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import App from './App.vue'
import router from './router'

import axios from "axios";
import VueAxios from "vue-axios";
import Vuelidate from 'vuelidate';

window.$ = window.jQuery = require('jquery');

//axios.defaults.baseURL = "https://pos.solincosta.com/BERLINUXERP/model/";
axios.defaults.baseURL = "https://www.berlinave.com/BERLINUXERP/model/";
//axios.defaults.baseURL = "http://localhost/BERLINUXERP/model/";
//axios.defaults.baseURL = 'http://localhost/berlinavenew/src/BERLINUXERP/model';

import PrimeVue from "primevue/config";

import Button from 'primevue/button';
import ProgressBar from 'primevue/progressbar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import Image from 'primevue/image';

import "primevue/resources/themes/saga-green/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import 'primeflex/primeflex.css' 

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";


library.add(fas);
library.add(faEnvelope);
library.add(faUser);
library.add(faFacebook);
library.add(faTwitter);
library.add(faInstagram);
library.add(faYoutube);

const app = createApp(App);

import util from './util/util';

app.config.globalProperties.$utilidad = util;

app.component("font-awesome-icon", FontAwesomeIcon);
app.component("Button", Button);
app.component("Carousel", DataTable);
app.component("Column", Column);
app.component("ProgressBar", ProgressBar);
app.component("RadioButton", RadioButton);
app.component("Checkbox", Checkbox);
app.component("Calendar", Calendar);
app.component("Dialog", Dialog);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Dropdown", Dropdown);
app.component("Image", Image);

//FORM PQR

app.use(router);
app.use(VueAxios, axios);


const options = {
  confirmButtonColor: "#004d27",
  cancelButtonColor: "#E71310",
};

app.use(VueSweetalert2, options);
app.use(Vuelidate);

app.use(PrimeVue, {
  locale: {
    accept: "Si",
    reject: "No",
    choose: "Elegir",
    upload: "Subir",
    cancel: "Cancelar",
    dayNames: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    monthNames: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    monthNamesShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    today: "Today",
    weekHeader: "Wk",
    firstDayOfWeek: 0,
    dateFormat: "mm/dd/yy",

    // ... más configuraciones
  },
});

app.mount("#app");