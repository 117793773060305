<template>
  <div class="inicio">
    <NavbarMain />
    <div v-show="showProgressNovedad">
        <ProgressBar mode="indeterminate" style="height: .5em" />
      </div>
    <div class="container">
      
      <div class="titulo-publicacion">
        <h3>{{ publicacion.titulo }}</h3>
      </div>
      <div class="fecha-publicacion">
        <span class="badge badge-pill badge-light">Publicado:</span>
        <span class="badge badge-pill badge-secondary">{{
          publicacion.fecha
        }}</span>
      </div>
      <div class="imagen-publicacion">
        <img
          :src="imagenpublicacion.icon"
          class="img-fluid"
          alt="Responsive image"
        />
      </div>
      <div class="publicacion-publicacion">
        <p><span v-html="publicacion.publicacion"></span></p>
        <!--<p>{{ publicacion.publicacion }}</p>-->
      </div>
      <div class="regresar">
        <router-link
                  :to="{ name: 'Inicio', params:{id:null }}"
                  class="btn btn-link"
                  >Regresar</router-link
                >
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import NavbarMain from "@/components/NavbarMain.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Noticia",

  data() {
    return {
      publicacion: {},
      showProgressNovedad: false
    };
  },

  components: {
    NavbarMain,
    Footer,
  },

  methods: {
    getpublicacionxid: async function() {
      let vue = this;
      this.showProgressNovedad = true;
      await this.axios
        .get(
          "publicaciones.php?dato=getpublicacionapi&id=" +
            this.$route.params.id
        )
        .then(function(response) {
          //vue.publicaciones = response.data;
          //console.log(response.data);
          vue.publicacion = response.data;
        })
        .catch((error) => {
          console.log("ERROR: " + error);
          console.log("ERROR responseText: " + error.responseText);
          console.log("ERROR STATUS: " + error.status);
          console.log("ERROR TEXT_STATUS: " + error.textStatus);
        })
        .then(function() {
          vue.showProgressNovedad = false;
        });
    },
  },

  computed: {
    //<!-- :src="require(`@/assets/${publicacion.imagen}`)" -->
    imagenpublicacion() {
      return {
        ...this.publicacion,
        icon:
          this.publicacion.imagen &&
          require(`@/assets/${this.publicacion.imagen}`),
      };
    },
  },

  mounted: function() {
    this.getpublicacionxid();
  },
};
</script>

<style>
* {
  font-family: "Poppins", sans-serif;
}

.titulo-publicacion h3 {
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
}

.fecha-publicacion {
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
  margin-top: 20px;
}

.imagen-publicacion {
  margin-top: 30px;
  margin-bottom: 30px;
}

.titulo p {
  color: #004d27;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}

.slider .slide .info p {
  font-size: 1em;
  font-weight: 400;
}
</style>
