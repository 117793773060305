<template>
  <div class="card" style="width: 18rem;">
    <img :src="require(`@/assets/${imagen}`)" class="card-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title">{{ titulo }}</h5>
      <p class="card-text">
        <span class="badge badge-pill badge-light">Publicado:</span>
        <span class="badge bg-warning text-dark mb-2">{{ fecha }}</span>
      </p>

      <div class="card-footer text-muted">
       <router-link
        :to="{ name: 'Noticia', params: { id: id_publicacion } }"
        class="btn btn-link"
        >Ver mas</router-link
      >
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "Noticias",

  props: {
    imagen: String,
    titulo: String,
    fecha: String,
    id_publicacion: Number,
  },
};
</script>

<style>
.titulo p {
  color: #004d27;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}

.nt-inicio .card img {
  height: 200px;
  object-fit: cover;
}

.nt-inicio .card {
  height: 100%;
}
</style>
